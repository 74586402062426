<script>
import def from "./default.vue";
export default {
  extends: def,
  data() {
    return {
      links: [
        { format: this.$t("format.title") },
        { styling: this.$t("design.title") },
        { services: this.$t("services.title") },
        { time: this.$t("time.title") },
        { settings: this.$t("settings.title") },
        { form: this.$t("form.title") },
        { survey: this.$t("survey.title") },
        { preview: this.$t("default.preview.title") }
      ]
    };
  },
  methods: {
    checkStatus(index, data) {
      if (!data.type && index > 0) {
        return false;
      }

      if (!this.$store.state.service.services.length && index > 2) {
        return false;
      }

      if (!Object.keys(data.include.weekdays).length && index > 1) {
        return false;
      }

      if (!data.title && index > 1) {
        return false;
      }

      if (!data.form.length && index > 5) {
        return false;
      }

      return true;
    }
  }
};
</script>
